<template>
  <WsMain>
    <WsCrud
      :modelName="$store.state.stone_model[modelName].modelName"
      :label="$store.state.stone_model[modelName].label"
      :fields="$store.state.stone_model[modelName].fields"
      :liveSearching="true"
      :showFields="showFields"
      :infiniteScroll="true"
      :creatable="false"
      :updatable="false"
    ></WsCrud>
  </WsMain>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "服務中心公告",
    };
  },

  data: () => ({
    modelName: "notification",
    showFields: ["id", "user", "notifiable_typ", "created_at"],
  }),
};
</script>